export const seo = {
  url: 'polityka-firmy',
  title: {
    pl: 'Dokumenty | Polityka Firmy | Komunikaty',
    en: 'Documents | Company policy | Messages',
  },
  desc: {
    pl: 'Zapoznaj się z dokumentacją Omida VLS, polityką prywatności, jakości oraz rekrutacji. Sprawdź najnowsze komunikaty zarządu.',
    en: 'Familiarize yourself with Omida VLS documentation, privacy, quality and recruitment policy. Check the latest board announcements.',
  },
  keywords: [],
}

export const intro = {
  title: {
    pl: 'Polityka Firmy',
    en: 'Company Policies',
  },
  desc: {
    pl: 'Zapoznaj się z dokumentacją Omida VLS, polityką prywatności, jakości oraz rekrutacji. Sprawdź najnowsze komunikaty zarządu.',
    en: 'Familiarize yourself with Omida VLS documentation, privacy, quality and recruitment policy. Check the latest board announcements..',
  },
  button: {
    text: {
      pl: 'Szukaj dokumentu',
      en: 'Explore documentation',
    },
    action: 'SCROLL',
  },
}
